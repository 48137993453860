import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import InlineSvg from 'vue-inline-svg';
import * as yup from 'yup';
import es from 'yup-es';
import Locales from './locales/locales';
import './css/application.css';
import BaseButton from './components/shared/base-button.vue';
import BaseError from './components/shared/base-error.vue';
import BaseInput from './components/shared/base-input.vue';
import LoginForm from './components/login/login-form.vue';
import HomeDashboard from './components/home/home-dashboard.vue';
import TheNavbar from './components/shared/the-navbar.vue';
import EditProfileForm from './components/profile/edit-profile-form.vue';
yup.setLocale(es);
const i18n = createI18n({
    legacy: false,
    locale: 'es',
    fallbackLocale: 'es',
    messages: Locales.messages,
});
const globalComponents = {
    BaseButton,
    BaseError,
    BaseInput,
    InlineSvg,
};
document.addEventListener('DOMContentLoaded', () => {
    const app = createApp({
        components: { LoginForm, HomeDashboard, TheNavbar, EditProfileForm },
    });
    Object.entries(globalComponents).forEach(([name, component]) => {
        app.component(name, component);
    });
    app.use(i18n);
    app.mount('#vue-app');
    return app;
});
