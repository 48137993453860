export default {
    users: {
        actions: {
            login: 'Iniciar sesión',
            logout: 'Cerrar sesión',
            updateProfile: 'Actualizar Perfil',
        },
        fields: {
            email: 'Correo electrónico',
            // file deepcode ignore NoHardcodedPasswords: not a hardcoded password, just a translation
            password: 'Contraseña',
            newPassword: 'Contraseña Nueva',
            passwordConfirmation: 'Confirmar Contraseña',
            currentPassword: 'Contraseña Actual',
        },
        titles: {
            login: 'Iniciar sesión',
            editPassword: 'Editar Contraseña',
            editProfile: 'Editar Perfil',
        },
    },
};
