import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "m-2 w-max rounded-md bg-gray-700 py-2" };
const _hoisted_2 = { class: "h-10 px-2 text-center text-xl text-white" };
const _hoisted_3 = { class: "w-full table-auto border-collapse" };
const _hoisted_4 = { class: "px-2" };
const _hoisted_5 = { class: "px-2 text-white" };
const _hoisted_6 = { class: "px-2 text-red-500" };
const _hoisted_7 = { class: "px-2 text-white opacity-40" };
const _hoisted_8 = { class: "h-9 bg-zinc-800 text-white" };
const _hoisted_9 = { class: "px-2" };
const _hoisted_10 = { class: "px-2" };
const _hoisted_11 = { class: "px-2 text-white" };
const _hoisted_12 = { class: "px-2 text-teal-500" };
const _hoisted_13 = { class: "px-2 text-white opacity-40" };
import { formatMoney } from 'utils/format-money';
import { formatPercentage } from 'utils/format-percentage';
const ordersPerSide = 6;
export default /*@__PURE__*/ _defineComponent({
    __name: 'order-book-table',
    props: {
        exchangeName: {},
        orderBook: {}
    },
    setup(__props) {
        const props = __props;
        const [baseCurrency, quoteCurrency] = props.orderBook.market_id.split('-');
        const asks = [...props.orderBook.asks.slice(0, ordersPerSide)].reverse();
        const bids = [...props.orderBook.bids.slice(0, ordersPerSide)];
        const asksTip = asks.length >= ordersPerSide ? Number(asks[ordersPerSide - 1].price) : 1;
        const bidsTip = bids.length > 0 ? Number(bids[0].price) : 0;
        const spreadAmount = asksTip - bidsTip;
        const spreadPercentage = spreadAmount / asksTip;
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, " Libro de órdenes " + _toDisplayString(_ctx.exchangeName) + ": " + _toDisplayString(props.orderBook.market_id), 1),
                _createElementVNode("table", _hoisted_3, [
                    _cache[1] || (_cache[1] = _createElementVNode("thead", { class: "h-7 bg-zinc-800 px-2 text-white" }, [
                        _createElementVNode("tr", null, [
                            _createElementVNode("th", null, "Cantidad"),
                            _createElementVNode("th", null, "Precio"),
                            _createElementVNode("th", null, "Acumulado")
                        ])
                    ], -1)),
                    _createElementVNode("tbody", _hoisted_4, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(asks), (order) => {
                            return (_openBlock(), _createElementBlock("tr", {
                                key: [order.price, order.size].join('#'),
                                class: "h-7 border-b border-solid border-gray-700"
                            }, [
                                _createElementVNode("td", _hoisted_5, _toDisplayString(_unref(formatMoney)(Number(order.size), _unref(baseCurrency))), 1),
                                _createElementVNode("td", _hoisted_6, _toDisplayString(_unref(formatMoney)(Number(order.price), _unref(quoteCurrency))), 1),
                                _createElementVNode("td", _hoisted_7, _toDisplayString(_unref(formatMoney)(Number(order.accum_size), _unref(baseCurrency))), 1)
                            ]));
                        }), 128)),
                        _createElementVNode("tr", _hoisted_8, [
                            _cache[0] || (_cache[0] = _createElementVNode("td", { class: "px-2 opacity-50" }, " Spread: ", -1)),
                            _createElementVNode("td", _hoisted_9, _toDisplayString(_unref(formatMoney)(spreadAmount, _unref(quoteCurrency))), 1),
                            _createElementVNode("td", _hoisted_10, _toDisplayString(_unref(formatPercentage)(spreadPercentage)), 1)
                        ]),
                        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(bids, (order) => {
                            return _createElementVNode("tr", {
                                key: [order.price, order.size].join('#'),
                                class: "h-7 border-b border-solid border-gray-700"
                            }, [
                                _createElementVNode("td", _hoisted_11, _toDisplayString(_unref(formatMoney)(Number(order.size), _unref(baseCurrency))), 1),
                                _createElementVNode("td", _hoisted_12, _toDisplayString(_unref(formatMoney)(Number(order.price), _unref(quoteCurrency))), 1),
                                _createElementVNode("td", _hoisted_13, _toDisplayString(_unref(formatMoney)(Number(order.accum_size), _unref(baseCurrency))), 1)
                            ]);
                        }), 64))
                    ])
                ])
            ]));
        };
    }
});
