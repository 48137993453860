import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "flex" };
import orderBookTable from './order-book-table.vue';
import theNavbar from '../shared/the-navbar.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'home-dashboard',
    props: {
        budaBtcClpOrderBook: {},
        budaBtcUsdcOrderBook: {},
        budaUsdcClpOrderBook: {},
        usdClpPrice: {},
        binanceBtcUsdtOrderBook: {}
    },
    setup(__props) {
        const props = __props;
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
                _createVNode(theNavbar, {
                    "usd-clp-price": Number(props.usdClpPrice)
                }, null, 8, ["usd-clp-price"]),
                _createElementVNode("div", _hoisted_1, [
                    _createVNode(orderBookTable, {
                        "order-book": JSON.parse(props.budaBtcClpOrderBook),
                        "exchange-name": "Buda"
                    }, null, 8, ["order-book"]),
                    _createVNode(orderBookTable, {
                        "order-book": JSON.parse(props.budaUsdcClpOrderBook),
                        "exchange-name": "Buda"
                    }, null, 8, ["order-book"]),
                    _createVNode(orderBookTable, {
                        "order-book": JSON.parse(props.budaBtcUsdcOrderBook),
                        "exchange-name": "Buda"
                    }, null, 8, ["order-book"]),
                    _createVNode(orderBookTable, {
                        "order-book": JSON.parse(props.binanceBtcUsdtOrderBook),
                        "exchange-name": "Binance"
                    }, null, 8, ["order-book"])
                ])
            ], 64));
        };
    }
});
