import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue";
const _hoisted_1 = { class: "flex justify-between bg-gray-900 p-2" };
const _hoisted_2 = { href: "/" };
const _hoisted_3 = ["src"];
const _hoisted_4 = {
    key: 0,
    class: "flex flex-col justify-around"
};
const _hoisted_5 = { class: "h-max rounded-md bg-gray-700 p-2 text-white" };
const _hoisted_6 = { class: "flex flex-col justify-between" };
const _hoisted_7 = {
    class: "inline-flex h-max justify-center space-x-2 rounded-md bg-blue-700 py-2 px-6 text-white",
    href: "/users/edit"
};
import { csrfToken } from 'utils/csrf';
import { formatMoney } from 'utils/format-money';
export default /*@__PURE__*/ _defineComponent({
    __name: 'the-navbar',
    props: {
        usdClpPrice: { default: undefined }
    },
    setup(__props) {
        function signOut() {
            fetch('/users/sign_out', {
                method: 'DELETE',
                headers: {
                    'X-CSRF-Token': csrfToken(),
                },
            }).then(() => window.location.replace('/users/sign_in')).catch();
        }
        const props = __props;
        return (_ctx, _cache) => {
            const _component_base_button = _resolveComponent("base-button");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("a", _hoisted_2, [
                    _createElementVNode("img", {
                        class: "h-24 self-center",
                        alt: "logo",
                        src: require('assets/images/logo.svg')
                    }, null, 8, _hoisted_3)
                ]),
                (!!props.usdClpPrice)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createElementVNode("span", _hoisted_5, " Larrainvial USD/CLP: " + _toDisplayString(_unref(formatMoney)(props.usdClpPrice, 'CLP', { maximumFractionDigits: 2 })), 1)
                    ]))
                    : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_base_button, {
                        type: "button",
                        theme: "secondary",
                        class: "justify-center",
                        label: "users.actions.logout",
                        onClick: signOut
                    }),
                    _createElementVNode("a", _hoisted_7, _toDisplayString(_ctx.$t('users.actions.updateProfile')), 1)
                ])
            ]));
        };
    }
});
