import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "text-left text-xs text-red-700" };
export default /*@__PURE__*/ _defineComponent({
    __name: 'base-error',
    props: {
        message: {}
    },
    setup(__props) {
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("p", null, _toDisplayString(_ctx.message), 1)
            ]));
        };
    }
});
