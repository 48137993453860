import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["for"];
const _hoisted_2 = ["id", "name", "value", "type", "placeholder"];
import { useField } from 'vee-validate';
import { useAttrs } from 'vue';
import { useI18n } from 'vue-i18n';
export default /*@__PURE__*/ _defineComponent({
    __name: 'base-input',
    props: {
        modelValue: { default: undefined },
        label: {},
        name: {},
        type: { default: 'text' },
        placeholder: {}
    },
    emits: ["update:modelValue"],
    setup(__props, { emit: __emit }) {
        const { t } = useI18n({});
        const props = __props;
        const attrs = useAttrs();
        const inputAttributes = { ...attrs };
        delete inputAttributes.class;
        const emit = __emit;
        const { value: inputValue, errorMessage, meta, handleBlur, handleChange, } = useField(props.name, undefined, {
            initialValue: props.modelValue,
        });
        const inputStyle = !!errorMessage && meta.touched ? 'placeholder:text-red-300 border-red-600' : 'border-gray-300';
        function handleInputChange(e) {
            handleChange(e);
            const input = e.target;
            emit('update:modelValue', input.value);
        }
        return (_ctx, _cache) => {
            const _component_base_error = _resolveComponent("base-error");
            return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["flex flex-col space-y-1", _ctx.$attrs.class])
            }, [
                _createElementVNode("label", {
                    for: _ctx.name,
                    class: "text-sm text-gray-700"
                }, _toDisplayString(_unref(t)(_ctx.label)), 9, _hoisted_1),
                _createElementVNode("input", _mergeProps({
                    id: _ctx.name,
                    class: ["h-10 w-full rounded border border-gray-100 px-2 text-gray-700 shadow-sm placeholder:text-sm", _unref(inputStyle)],
                    name: _ctx.name,
                    value: _unref(inputValue),
                    type: _ctx.type,
                    placeholder: _unref(t)(_ctx.placeholder)
                }, inputAttributes, {
                    onInput: handleInputChange,
                    onBlur: _cache[0] || (_cache[0] =
                        //@ts-ignore
                        (...args) => (_unref(handleBlur) && _unref(handleBlur)(...args)))
                }), null, 16, _hoisted_2),
                (_unref(errorMessage) && _unref(meta).touched)
                    ? (_openBlock(), _createBlock(_component_base_error, {
                        key: 0,
                        "data-testid": "base-input-error",
                        message: _unref(errorMessage)
                    }, null, 8, ["message"]))
                    : _createCommentVNode("", true)
            ], 2));
        };
    }
});
