import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = ["type"];
const _hoisted_2 = {
    key: 1,
    "data-testid": "base-button-label",
    class: "grow"
};
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
export default /*@__PURE__*/ _defineComponent({
    __name: 'base-button',
    props: {
        loading: { type: Boolean, default: false },
        label: { default: undefined },
        icon: { default: undefined },
        iconAlt: { default: 'shared.icon' },
        type: { default: 'button' },
        theme: { default: 'primary' },
        resize: { type: Boolean, default: false },
        border: { type: Boolean, default: false },
        shadow: { type: Boolean, default: false },
        size: { default: 'md' },
        rounded: { type: Boolean, default: false },
        focusable: { type: Boolean, default: true }
    },
    setup(__props) {
        const { t } = useI18n({});
        const props = __props;
        const colors = {
            primary: 'bg-green-800 text-white',
            secondary: 'bg-blue-700 text-white',
            'light-primary': 'text-green focus:font-semibold',
            'light-secondary': 'text-gray-400 focus:text-green focus:font-semibold',
            'light-tertiary': 'text-blue-900 focus:font-semibold',
        };
        const borders = {
            primary: 'border-2 border-green',
            secondary: 'border-2 border-blue-700',
            'light-primary': 'border-2 border-green ',
            'light-secondary': 'border-2 border-gray-200 focus:border-green ',
            'light-tertiary': 'border-2 border-gray-200',
        };
        const focus = {
            primary: '',
            secondary: '',
            'light-primary': 'focus:font-semibold',
            'light-secondary': 'focus:text-green focus:font-semibold',
            'light-tertiary': 'focus:font-semibold',
        };
        const sizes = {
            sm: 'p-2',
            md: 'py-2 px-6',
        };
        const roundedStyle = props.rounded ? 'rounded-full' : 'rounded-md';
        const styles = computed(() => [
            colors[props.theme],
            sizes[props.size],
            roundedStyle,
            props.border && borders[props.theme],
            props.focusable && focus[props.theme],
            { 'text-xs md:text-base': props.resize },
            { 'shadow-md': props.shadow },
        ]);
        return (_ctx, _cache) => {
            const _component_inline_svg = _resolveComponent("inline-svg");
            return (_openBlock(), _createElementBlock("button", {
                class: _normalizeClass(["inline-flex h-max space-x-2", styles.value]),
                type: _ctx.type
            }, [
                (_ctx.loading)
                    ? (_openBlock(), _createBlock(_component_inline_svg, {
                        key: 0,
                        src: require('assets/images/icons/loading.svg'),
                        class: "h-6 animate-spin stroke-current",
                        "data-testid": "base-button-loading-icon"
                    }, null, 8, ["src"]))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        (_ctx.icon)
                            ? (_openBlock(), _createBlock(_component_inline_svg, {
                                key: 0,
                                src: require(`assets/images/icons/${_ctx.icon}.svg`),
                                alt: _unref(t)(_ctx.iconAlt),
                                class: "h-6 fill-current stroke-current",
                                "data-testid": "base-button-icon"
                            }, null, 8, ["src", "alt"]))
                            : _createCommentVNode("", true),
                        (_ctx.label)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_unref(t)(_ctx.label)), 1))
                            : _createCommentVNode("", true)
                    ], 64))
            ], 10, _hoisted_1));
        };
    }
});
