function formatCLP(amount, options = {}) {
    const defaultCurrencyOptions = {
        currency: 'CLP', style: 'currency', maximumFractionDigits: 0,
    };
    return amount.toLocaleString('es-CL', { ...defaultCurrencyOptions, ...options });
}
function formatBTC(amount, options = {}) {
    const defaultCurrencyOptions = {
        minimumFractionDigits: 8, maximumFractionDigits: 8,
    };
    return `฿${amount.toLocaleString('es-CL', { ...defaultCurrencyOptions, ...options })}`;
}
function formatUSD(amount, options = {}) {
    const defaultCurrencyOptions = {
        currency: 'USD', style: 'currency', maximumFractionDigits: 2,
    };
    return amount.toLocaleString('es-CL', { ...defaultCurrencyOptions, ...options });
}
const currencyToFormatter = {
    'CLP': formatCLP,
    'BTC': formatBTC,
    'USD': formatUSD,
    'USDC': formatUSD,
    'BUSD': formatUSD,
    'USDT': formatUSD,
};
function formatMoney(amount, currency, options = {}) {
    const formatter = currencyToFormatter[currency];
    return formatter(amount, options);
}
export { formatMoney };
