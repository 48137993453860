import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode } from "vue";
const _hoisted_1 = { class: "mt-24 flex w-full items-center justify-center bg-white" };
const _hoisted_2 = { class: "mx-6 flex w-full flex-col gap-y-4 rounded-2xl border border-gray-200 p-8 shadow-xl sm:w-96" };
const _hoisted_3 = {
    name: "title",
    class: "text-2xl text-gray-600"
};
export default /*@__PURE__*/ _defineComponent({
    __name: 'edit-profile-form',
    props: {
        errors: {},
        userEmail: {}
    },
    setup(__props) {
        const props = __props;
        return (_ctx, _cache) => {
            const _component_base_error = _resolveComponent("base-error");
            const _component_base_input = _resolveComponent("base-input");
            const _component_base_button = _resolveComponent("base-button");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t('users.titles.editProfile')), 1),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.errors, (error) => {
                        return (_openBlock(), _createBlock(_component_base_error, {
                            key: `error-${error}`,
                            message: error
                        }, null, 8, ["message"]));
                    }), 128)),
                    _createVNode(_component_base_input, {
                        "model-value": _ctx.userEmail,
                        label: "users.fields.email",
                        name: "user[email]",
                        type: "text",
                        placeholder: "users.fields.email"
                    }, null, 8, ["model-value"]),
                    _createVNode(_component_base_input, {
                        label: "users.fields.password",
                        name: "user[password]",
                        type: "password",
                        placeholder: "users.fields.password"
                    }),
                    _createVNode(_component_base_input, {
                        label: "users.fields.passwordConfirmation",
                        name: "user[password_confirmation]",
                        type: "password",
                        placeholder: "users.fields.password"
                    }),
                    _createVNode(_component_base_input, {
                        label: "users.fields.currentPassword",
                        name: "user[current_password]",
                        type: "password",
                        placeholder: "users.fields.currentPassword"
                    }),
                    _createVNode(_component_base_button, {
                        type: "submit",
                        name: "commit",
                        theme: "primary",
                        class: "justify-center",
                        label: "users.actions.updateProfile"
                    })
                ])
            ]));
        };
    }
});
